import cx from 'classnames'
import { motion } from 'framer-motion'
interface Props {
  children: JSX.Element | string
  animate?: boolean
  testId?: string
  className?: string
}

const Header = ({ children, animate = true, testId, className }: Props) => {
  return (
    <motion.h1
      data-testid={`${testId}-header`}
      className={cx(
        'mb-3 text-center text-3xl font-bold sm:mb-9 sm:text-4xl',
        className ? className : 'mx-4 sm:mx-6',
      )}
      initial={animate ? { opacity: 0, translateY: '1rem' } : false}
      animate={{ opacity: 1, translateY: 0 }}
    >
      {children}
    </motion.h1>
  )
}

export default Header
