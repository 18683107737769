import create from 'zustand'

type InitialOnboardingMC = {
  code: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
}

type MCOnboardingStore = {
  email: string | null
  code: string | null
  firstName: string | null
  lastName: string | null
  setInitialOnboardingMC: ({
    code,
    email,
    firstName,
    lastName,
  }: InitialOnboardingMC) => void
}

const useMcOnboardingStore = create<MCOnboardingStore>((set) => ({
  code: null,
  email: null,
  firstName: null,
  lastName: null,

  setInitialOnboardingMC: ({
    code,
    email,
    firstName,
    lastName,
  }: InitialOnboardingMC) => set({ code, email, firstName, lastName }),
}))

export default useMcOnboardingStore
