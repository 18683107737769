import UploadCloudSVG from '~shared/components/Icons/svg/cloud-upload.svg'

import { SetupOptionsType } from '@/dashboard/components/Dashboard/SetupOption'

type SetupOptionsProps = {
  mcId: string | null | undefined
  canImportDrivers: boolean
}

const getOnboardingDashboardSetupOptions = ({
  canImportDrivers,
}: SetupOptionsProps): SetupOptionsType[] => [
  {
    testId: 'set-up-payments-option',
    icon: '/images/forms.svg',
    href: '/onboarding/kyc',
    title: 'Set up payments',
    description: `{Courier Network} require KYC checks on your business.`,
    buttonName: 'Go to KYC.',
  },
  {
    testId: 'add-users-option',
    icon: '/images/user-icon.svg',
    href: '/settings/users',
    title: 'Add users',
    description:
      'Add other users within your business to give them access to your Wise platform.',
    buttonName: 'Go to users.',
  },
  {
    testId: 'add-policies-option',
    icon: '/images/clipboard.svg',
    href: '/settings/document-library',
    title: 'Add policies',
    description:
      'You can add any policies you want adding to your driver onboarding process.',
    buttonName: 'Go to policies.',
  },
  {
    testId: 'add-drivers-option',
    icon: '/images/driving-licence.svg',
    href: '/onboarding/add-drivers',
    title: 'Add drivers',
    description:
      'We need some basic data about your drivers to set them up on the system.',
    buttonName: 'Upload drivers.',
    buttonIcon: <UploadCloudSVG height={18} width={18} />,
    isDisabled: !canImportDrivers,
  },
]

export default getOnboardingDashboardSetupOptions
