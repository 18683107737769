export function maybe<T>(fn: () => T): T | undefined
export function maybe<T>(fn: () => Promise<T>): Promise<T | undefined>
export function maybe<T>(
  fn: () => T | Promise<T>,
): T | undefined | Promise<T | undefined> {
  try {
    const result = fn()
    if (result instanceof Promise) {
      return result.catch(() => undefined)
    }
    return result
  } catch (e) {
    return undefined
  }
}
