interface Props {
  titleText1: string
  titleText2: string
}

const Title = ({ titleText1, titleText2 }: Props) => (
  <span>
    {`${titleText1} `}
    <span className='inline-block bg-gradient-to-r from-theme to-theme-light bg-clip-text text-transparent'>
      {titleText2}
    </span>
  </span>
)

export default Title
