import { motion } from 'framer-motion'
import { kebabCase } from 'lodash'
import { useRouter } from 'next/router'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import Loading from '~shared/components/Loading/Loading'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'

interface Props {
  buttonName?: React.ReactChild | string
  testId?: string
  href?: string
  handleSubmit?: () => Promise<unknown> | void
  buttonDisabled?: boolean
  buttonIcon?: JSX.Element
}
const SetupOptionButton = ({
  buttonName,
  testId,
  href,
  handleSubmit,
  buttonDisabled = false,
  buttonIcon,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const router = useRouter()

  const handleClick = React.useCallback(async () => {
    if (href) return router.push(href)
    if (handleSubmit) {
      try {
        setIsLoading(true)
        return await handleSubmit()
      } catch (error) {
        if (error instanceof Error) {
          triggerErrorModal({ error })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [handleSubmit, href, router])

  return (
    <motion.div
      className='flex flex-col items-stretch sm:items-center'
      initial={{ scale: 1 }}
      animate={{ scale: isLoading ? 0.99 : 1, opacity: isLoading ? 0.75 : 1 }}
      whileTap={{ scale: 0.96 }}
    >
      <button
        data-testid={`${testId ? kebabCase(testId) : undefined}-button`}
        className={twMerge(
          'rounded-lg bg-black px-6 py-3 text-sm font-bold tracking-wider text-white shadow-md disabled:cursor-not-allowed',
          buttonDisabled ? null : 'hover:bg-purple',
        )}
        onClick={handleClick}
        disabled={buttonDisabled || isLoading}
      >
        <motion.div
          initial={{ opacity: 1, scale: 1 }}
          animate={{
            opacity: isLoading ? 0.15 : 1,
            scale: isLoading ? 0.8 : 1,
          }}
          className='flex flex-row items-center justify-center gap-x-2 text-center tracking-wider'
        >
          {buttonIcon ? buttonIcon : null}
          {buttonName}
        </motion.div>
        {isLoading ? (
          <motion.div
            className='absolute inset-0 flex items-center justify-center'
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: isLoading ? 1 : 0, scale: isLoading ? 1 : 0.3 }}
          >
            <Loading />
          </motion.div>
        ) : null}
      </button>
    </motion.div>
  )
}

export default SetupOptionButton
