import { motion } from 'framer-motion'
import { kebabCase } from 'lodash'
import { twMerge } from 'tailwind-merge'

import SetupOptionButton from '@/dashboard/components/Dashboard/SetupOptionButton'

export interface SetupOptionsType {
  icon: string
  title: string
  description: React.ReactChild | string
  buttonName?: React.ReactChild | string
  buttonIcon?: JSX.Element
  testId?: string
  href?: string
  handleSubmit?: () => Promise<unknown> | void
  customButton?: React.ReactChild
  isDisabled?: boolean
}

const SetupOption = ({
  icon,
  title,
  description,
  testId,
  buttonName,
  buttonIcon,
  href,
  handleSubmit,
  customButton,
  isDisabled,
}: SetupOptionsType): JSX.Element => {
  return (
    <motion.div
      data-testid={testId ? kebabCase(testId) : undefined}
      className={twMerge(
        'mb-6 flex h-[18rem] flex-col items-center rounded-xl bg-white px-3 py-6 drop-shadow-lg sm:h-[26rem] sm:w-[20rem] sm:justify-start sm:px-5 sm:py-12',
        isDisabled ? 'bg-grey-darker drop-shadow-none' : null,
      )}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDisabled ? 0.5 : 1 }}
    >
      <div className='m-2 flex flex-col items-center justify-between sm:min-h-[160px]'>
        <img
          src={icon}
          alt={title + ' image'}
          className='mb-3 h-full w-20 items-center sm:w-[5.6rem]'
        />
        <h2 className='text-2xl font-extrabold'>
          {title}
          <span className='text-purple-400'>.</span>
        </h2>
      </div>
      <div className='mt-auto flex h-full flex-col items-center justify-between'>
        <div className='text-center text-sm font-medium leading-5 tracking-wide'>
          {description}
        </div>
        <div className='flex max-w-200 items-end justify-center'>
          {customButton ? (
            customButton
          ) : (
            <SetupOptionButton
              buttonDisabled={isDisabled}
              buttonName={buttonName}
              href={href}
              handleSubmit={handleSubmit}
              buttonIcon={buttonIcon}
            />
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default SetupOption
